.project-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  cursor: pointer;
}

.project-container:hover {
  background-color: lightgray;
}

.results {
  font-style: italic;
}

.tech {
  font-style: italic;
  color: gray;
}

.project-link {
  margin-left: 1rem;
}

.resume-page-header {
  background-color: #343a40;
}

.resume-page-header .ant-page-header-heading-title {
  color: white;
}
.resume-page-header .ant-page-header-heading-sub-title {
  color: lightgray;
}

.section {
  border-top: 1px solid black;
  margin-bottom: 2vh;
  padding-top: 1vh;
}

.section-header {
  font-weight: bold;
  font-size: 3vh;
}

.date {
  font-style: italic;
}

.print-header {
  font-weight: bold;
  font-size: 150%;
  border-bottom: 6px double black;
  display: none;
  justify-content: space-between;
  padding: 5px;
}

.print-header * {
  white-space: nowrap;
}

.resume-container {
  display: grid;
  grid-gap: 5px;
  //grid-template-columns: 3fr 1fr;
}

.align-right {
  text-align: end;
}

@media print {
  .resume-page-header {
    display: none;
  }
  .print-header {
    display: flex;
  }
}

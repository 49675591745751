.filter-container {
  margin-bottom: 10px;
  background-color: lightgray;
}

@media print {
  .filter-container {
    display: none;
  }
}

.filter-container .instructions {
  text-weight: bold;
  padding: 5px;
}

.filter-section {
  display: grid;
  grid-template-columns: 1fr 7fr;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-section-label {
  white-space: nowrap;
  text-align: left;
  padding-left: 10px;
}

.filter-buttons {
  grid-column: 2;
  display: flex;
  justify-content: space-around;
}

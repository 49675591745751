.job-container,
.job-project-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  cursor: pointer;
}

.job-container:hover,
.job-project-container:hover {
  background-color: lightgray;
}

.job-link {
  margin-left: 1rem;
}

.highlight ul {
  padding-left: 15px;
}

.results {
  font-style: italic;
}

.tech {
  font-style: italic;
  color: gray;
}

.duration {
  font-style: italic;
}

.employer {
  font-weight: bold;
  text-decoration: underline;
}

.job-project {
  margin-left: 2vh;
}

.job-role {
  margin-left: 1vh;
  font-weight: bold;
}

.project-name {
  font-weight: bold;
}

.job-project-name {
  font-style: italic;
}

.job-project-name::after {
  content: " - ";
}

.project-role {
  text-decoration: underline;
}

.project-members::before {
  content: ", ";
}

.project-highlight {
  margin-left: 3vh;
}

.project-tech {
  font-style: italic;
  color: gray;
}

.project-blurb {
  font-style: italic;
  font-size: 95%;
  margin-left: 4vh;
}

.subsubheading {
  text-decoration: underline;
}

.job-company,
.job-project {
  cursor: pointer;
}

.job-company:hover,
.job-project:hover {
  background-color: lightgray;
}
